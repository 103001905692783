import React from 'react';
import './App.css';
import WholePageView from "./components/WholePageView";

function App() {
  return (
    <div className="App">
      <WholePageView/>
    </div>
  );
}

export default App;
